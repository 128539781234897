<template>
  <div class="ml-quiz-detail mb-5">
    <div class="container" v-if="item">
      <div class="breadcrumd-custom"><nuxt-link to="/trac-nghiem">Bộ đề</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Chi tiết</span></div>
      <div v-if="!showGuide" class="row">
        <div class="col-12 order-1">
          <div class="quiz-name font-weight-bold text-break">{{item.name}}</div>
        </div>
        <div class="order-2 col-lg-9 order-lg-2">
          <div class="d-flex align-items-center">
            <span class="number-user font-italic mr-4 mr-lg-5">{{ item.number_people }} người đã làm</span>
            <span class="quiz-action pointer">
              <span :class="{'active text-primary': item.is_saved}" @click="openDropdown">
                <span v-html="require('~/assets/mlearn/icons/quiz/save.svg?raw')"/>
                <span class="sub-title ml-2">{{ item.is_saved ? 'Đã lưu' : 'Lưu' }}</span>
              </span>
              <Playlist field="exam_id" :item="item" :playlist="playlist" :open="open" @close="open = false" @addData="addData" @showError="showError" />
            </span>
          </div>
        </div>
        <div class="pt-4 pt-lg-0 order-4 col-lg-3 order-lg-3 quiz-top text-center">
          Top 10 điểm cao
        </div>
        <div class="order-3 col-lg-9 order-lg-4 mt-3">
          <div class="form-content bg-white">
            <div class="quiz-info font-weight-bold">
              Thời gian làm bài: <span>{{ item.time }}phút</span><b class="mx-2 mx-lg-4">|</b>
              Số câu: <span>{{item.number_question}}</span>
            </div>
            <div class="quiz-description mt-3" v-if="item.description" v-html="item.description"></div>
            <div class="user-action text-center">
              <button class="back mr-4 mr-lg-5" @click="goToPage('/trac-nghiem')">Quay lại</button>
              <template v-if="skipGuide">
                <button class="take" @click="redirectExam">Làm bài ngay</button>
              </template>
              <template v-else>
                <button class="take d-none d-lg-inline-block" @click="redirectExam">Làm bài ngay</button>
                <button class="take d-lg-none" @click="checkShowGuide">Làm bài ngay</button>
              </template>
            </div>
          </div>
        </div>
        <div class="order-5 col-lg-3 order-lg-5 mt-3">
          <div class="list-top scrollbar">
            <NoData v-if="!topScore.length" text="Hãy là người đầu tiên thử sức đề trắc nghiệm và điền tên mình vào Top 10 nhé !"/>
            <div class="top-item form-data" v-for="user in topScore" :key="'score'+user.created_by">
              <img :src="user.avatar || NoAvatar" class="avatar rounded-circle" alt="avatar">
              <div class="content font-weight-bold ml-3">
                <div class="text-break font-weight-bold">{{user.name}}</div>
                <div class="text-break font-weight-normal result">Đúng: <span class="font-weight-bold">{{user.total_correct}}/{{user.total_question}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="show-guide">
        <Guide/>
        <div class="p-3">
          <b-form-checkbox v-model="skipGuide">
            Đã hiểu và không hiện lại
          </b-form-checkbox>
        </div>
        <div class="text-center">
          <button class="take" @click="redirectExam">Bắt đầu làm bài</button>
        </div>
      </div>
      <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false"/>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import cookieParser from 'cookieparser'
import Resource from '~/common/api/resource'
import Playlist from '~/components/mlearn/playlist/index.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Guide from '~/components/mlearn/quiz/guide.vue'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
const examResource = new Resource('library/exam/getBySlug')
const playlistResource = new Resource('library/playlist')
const playlistExamResource = new Resource('library/playlist/exam')
const topScoreExamResource = new Resource('library/exam/topScore')
export default {
  components: {
    Playlist,
    PlaylistNotify,
    Guide
  },
  layout: 'mlearn',
  async asyncData ({ params, req, error }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    const tmp1 = await examResource.list({ slug: params.slug }, token)
      .catch(() => {
        return error({ statusCode: 404, title: 'Bài trắc nghiệm không tồn tại', message: 'Bài trắc nghiệm đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!tmp1) {
      return error({ statusCode: 404, title: 'Bài trắc nghiệm không tồn tại', message: 'Bài trắc nghiệm đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const item = tmp1.data
    const tmp2 = await topScoreExamResource.list({ exam_id: item.id }, token)
      .catch(() => {
        return error({ statusCode: 404, title: 'Bài trắc nghiệm không tồn tại', message: 'Bài trắc nghiệm đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!tmp2) {
      return error({ statusCode: 404, title: 'Bài trắc nghiệm không tồn tại', message: 'Bài trắc nghiệm đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const topScore = tmp2.data
    return {
      NoAvatar,
      item,
      playlist: [],
      processing: false,
      open: false,
      flagNotify: false,
      showGuide: false,
      skipGuide: false,
      textNotify: '',
      topScore
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    appUrl () {
      return process.env.NUXT_ENV_WEB_URL
    }
  },
  watch: {
    skipGuide (newValue, oldValue) {
      if (newValue) {
        localStorage.setItem('skipGuide', 1)
      } else {
        localStorage.removeItem('skipGuide')
      }
    }
  },
  created () {
    this.loadPlayList()
  },
  mounted () {
    const check = localStorage.getItem('skipGuide', 1)
    this.skipGuide = !!check
  },
  methods: {
    addData (form) {
      const message = `Đã lưu vào  "<b>${form.playlist_name}</b>"`
      if (form.playlist_id) {
        delete form.playlist_name
      }
      if (this.processing) { return }
      this.flagNotify = false
      this.processing = true
      playlistExamResource.store(form)
        .then((res) => {
          if (res.status === 200) {
            this.item.is_saved = true
            this.textNotify = message
            this.flagNotify = true
            this.open = false
            this.loadPlayList()
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    showError (error) {
      this.textNotify = error
      this.flagNotify = true
    },
    UnSave () {
      if (this.processing) { return }
      this.processing = true
      this.flagNotify = false
      playlistExamResource.destroy(this.item.id)
        .then((res) => {
          this.item.is_saved = false
          this.textNotify = 'Đã bỏ lưu'
          this.flagNotify = true
          this.loadPlayList()
        })
        .finally(() => {
          this.processing = false
        })
    },
    async loadPlayList () {
      if (!this.user) { return }
      const { data } = await playlistResource.list({ type: 'exam' })
      this.playlist = data
    },
    redirectExam () {
      if (!this.user) {
        this.$bvModal.show('modal-login')
        return
      }
      const url = this.appUrl + '/trac-nghiem/lam-bai/' + this.$route.params.slug
      window.open(url, '_blank').focus()
    },
    openDropdown () {
      if (!this.user) { // chưa đăng nhập
        this.$bvModal.show('modal-login')
        return
      }
      if (this.item.is_saved) {
        this.UnSave()
        return
      }
      this.open = true
    },
    goToPage (path = '') {
      if (!path) {
        return
      }
      this.$router.push(path)
    },
    checkShowGuide () {
      this.redirectExam()
      // const width = window.innerWidth
      // if (width < 992) {
      //   if (this.skipGuide) {
      //     this.redirectExam()
      //     return
      //   }
      // }
      // this.showGuide = true
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-quiz-detail{
  .quiz-name{
    font-size: 24px;
    @media (max-width:576px) {
      font-size: 16px;
    }
  }
  .number-user{
    color: #65676B;
    font-size: 16px;
    @media (max-width:576px) {
      font-size: 14px;
    }
  }
  .form-content{
    box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
    border-radius: 8px;
    padding:55px 70px;
    @media (max-width:576px) {
      padding:16px;
    }
    .quiz-info{
      font-size: 16px;
      @media (max-width:576px) {
        font-size: 14px;
      }
      span{
        color: var(--danger);
      }
    }
    .user-action{
      margin-top: 32px;
      @media (max-width:576px) {
        margin-top: 20px;
      }
      button{
        border: 1px solid var(--primary) !important;
        border-radius: 5px;
        text-align: center;
        background: var(--white);
        color: var(--primary);
        padding:10px 16px;
        font-size: 16px;
        @media (max-width:576px) {
          font-size: 14px;
          padding:10px 15px;
        }
        &.take{
          background: var(--primary);
          color: var(--white);
        }
      }
    }
  }
  .quiz-top{
    font-weight: 600;
    font-size: 16px;
    @media (max-width:576px) {
      font-size: 16px;
    }
  }
  .list-top{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
    border-radius: 5px;
    padding:0 16px 16px;
    @media (max-width:576px) {
      padding:0 10px 10px;
    }
    .top-item{
      padding:16px 0;
      border-bottom: 1px solid #EBEBEB;
      @media (max-width:576px) {
        padding:10px 0;
      }
      &:last-child{
        border-bottom: 0;
      }
      .avatar{
        width: 40px;
        height: 40px;
        @media (max-width:576px) {
          width: 36px;
          height: 36px;
        }
      }
      .content{
        font-size: 16px;
        @media (max-width:576px) {
          font-size: 14px;
        }
        .result{
          color: #65676B;
          span{
            color: var(--danger);
          }
        }
      }
    }
  }
  .show-guide{
    .ml-quiz-guide{
      background: var(--white);
      border-radius: 8px;
      height: auto !important;
    }
    button{
      border: 1px solid var(--primary) !important;
      border-radius: 22px;
      text-align: center;
      background: var(--white);
      color: var(--primary);
      padding:10px 30px;
      font-size: 16px;
      @media (max-width:576px) {
        font-size: 14px;
        padding:10px 25px;
      }
      &.take{
        background: var(--primary);
        color: var(--white);
      }
    }
  }
}
</style>
