<template>
  <div class="container ml-my-library min-vh-100 mb-5">
    <div class="breadcrumd-custom"><span class="active">&nbsp;</span></div>
    <div class="page-title font-weight-bold">Thư viện của tôi</div>
    <div class="tab-action d-flex align-items-center pt-2 mb-2">
      <span class="action pointer pb-2 pb-lg-3" :class="{'active text-primary': tabActive === 1}" @click="changeTab(1)">Khóa học</span>
      <span class="action pointer pb-2 pb-lg-3" :class="{'active text-primary': tabActive === 2}" @click="changeTab(2)">Tài liệu</span>
      <span class="action pointer pb-2 pb-lg-3" :class="{'active text-primary': tabActive === 3}" @click="changeTab(3)">Bộ đề</span>
    </div>
    <div v-if="tabActive === 1">
      <VideoPlaylist/>
    </div>
    <div v-if="tabActive === 2">
      <DocumentPlaylist/>
    </div>
    <div v-if="tabActive === 3">
      <QuizPlaylist/>
    </div>
    <ModalDelete :message="message"/>
  </div>
</template>

<script>
import ModalDelete from '~/components/mlearn/playlist/modal-delete.vue'
import VideoPlaylist from '~/components/mlearn/my-library/playlist/video/index.vue'
import DocumentPlaylist from '~/components/mlearn/my-library/playlist/document/index.vue'
import QuizPlaylist from '~/components/mlearn/my-library/playlist/quiz/index.vue'

export default {
  components: {
    ModalDelete,
    VideoPlaylist,
    DocumentPlaylist,
    QuizPlaylist
  },
  layout: 'mlearn',
  middleware: 'authenticated',
  asyncData ({
    isDev,
    route,
    store,
    env,
    params,
    query,
    req,
    res,
    redirect,
    error
  }) {
    let tabActive = query.tab || 1
    tabActive = parseInt(tabActive)
    return {
      tabActive,
      loading: false,
      message: ''
    }
  },
  methods: {
    changeTab (tab) {
      this.tabActive = tab
      const {
        type,
        ...rest
      } = this.$route.query
      this.$router.push({
        query: {
          ...rest,
          tab
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-my-library {
  .page-title {
    font-size: 24px;
    @media (max-width: 576px) {
      font-size: 16px;
    }
  }

  .tab-action {
    border-bottom: 1px solid #EBEBEB;

    .action {
      font-weight: 600;
      font-size: 14px;
      text-align: center;
      color: #65676B;
      margin-right: 32px;
      @media (max-width: 576px) {
        margin-right: 20px;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          bottom: 0;
          border-bottom: 2px solid var(--primary);
        }
      }
    }
  }
}
</style>
